<template>
    <div class="login-container">
        <div class="login-bg-container">
            <img :src="require('@/assets/img/login-bg.png')"/>
        </div>
        <span class="ower-info">苏ICP备2021030195号-1  Copyright by SuZhou RuiLi Wulian Technology Co. Ltd</span>
        <div class="login-form-container">
            <div class="login-logo-container">
                <img v-if="localUserInfo.orgLogo" class="default-logo" :src="localUserInfo.orgLogo" />
            </div>
            <div class="login-title">
                {{`${localUserInfo.platformName ? localUserInfo.orgName+localUserInfo.platformName : '电池运维管理平台'}`}}
            </div>
            <el-form
                ref="loginForm"
                :model="userInfo"
                :rules="rules"
                label-width="100px"
                class="login-from"
            >
                <el-form-item prop="account" label-width="24px" style="marginBottom: 30px !important;">
                    <div class="login-form-lable" slot="label">
                        <img class="user-icon" :src="require('@/assets/img/login-user.png')"/>
                    </div>
                    <el-input v-model="userInfo.account" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item prop="pwd" label-width="24px">
                    <div class="login-form-lable" slot="label">
                        <img class="user-icon" :src="require('@/assets/img/password-icon.png')"/>
                    </div>
                    <el-input v-model="userInfo.pwd" show-password placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label-width="0px">
                    <el-button class="login-button" :loading="loading" @click="loginHandel">登录</el-button>
                    <el-checkbox class="remeber-checkbox">记住密码</el-checkbox>
                    <span class="forget-pwd">忘记密码？</span>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axios';
import './Index.less';
export default {
    name: 'Login',
    data() {
        return {
            userInfo: {
                account: '',
                pwd: '',
                code: 1
            },
            rules: {
                account: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ]
            },
            loading: false
        }
    },
    computed: {
        localUserInfo() {
            const localUser =  localStorage.getItem('currentUser');
            if (localUser) {
                return JSON.parse(localUser).org
            }
            return {}
        }
    },
    methods: {
        loginHandel() {
            this.$refs['loginForm'].validate(valid => {
                if (valid) {
                    this.loading = true;
                    axios.post('/api/auth/login', this.userInfo).then(rs => {
                        const { token } = rs;
                        localStorage.setItem('user-token', token);
                        
                        axios.get('/api/userInfo/getCurrLoginUserInfo').then(currentUser => {
                            if (token) {
                                localStorage.setItem('currentUser', JSON.stringify(currentUser));
                                const roleId = currentUser.roleId;
                                localStorage.setItem('organId', currentUser.orgId);
                                localStorage.setItem('userName', currentUser.userName);
                                localStorage.setItem('userId', currentUser.id);
                                localStorage.setItem('roleId', currentUser.roleId);
                                this.loading = false;
                                localStorage.setItem('currentFuns', JSON.stringify(currentUser.permissions));
                                console.log(currentUser.permissions)
                                this.$router.push('/work-bench');
                                // axios.get(`/api/rolePermissions/getRolePermissionsList/${roleId}`).then(rs => {
                                //     this.loading = false;
                                //     const funs = rs.map(item => item.permissionsCode);
                                //     localStorage.setItem('currentFuns', JSON.stringify(funs));
                                //     this.$router.push('/device-management');
                                // });
                            }
                        });
                    }).catch(err => {
                        this.loading = false;
                        this.$message.error(err);
                    });
                }
            });
        }
    }
}
</script>